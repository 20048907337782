import React from "react";
import Link from "gatsby-link";
import Scaffold from "../../../components/portfolio";

import dotaCover from "../../../images/dotacover.jpg";

export default (props) => {
  return (
    <Scaffold pathname={props.location.pathname}>
      <h2>Clarity Coach</h2>

      <h3>The Plan</h3>

      <img
        src={dotaCover}
        style={{
          width: 647,
          height: 202,
          borderRadius: 8,
          boxShadow: "#00000078 0px 2px 6px 0px",
        }}
      />

      <p>
        Dota 2 is a very competitive, popular, online game where 10 players form
        two teams and play each other in matches that last around 40 minutes.
        Last year the prize pool for the final tournament was over $US 24
        million. At any one time there are around half a million Dota 2 players
        currently playing a match.
      </p>

      <p>
        For a lot of players the majority of the appeal of a game like Dota is
        the constant challenge and desire to get better at the game. There are
        large communities of players who get together to talk about new tactics
        and strategies and the evolving trends of the game, known as the meta.
        Some players pay others to coach them, which involves a coach watching
        replays and offering advice to their less skilled clients.
      </p>

      <p>
        The plan for Clarity Coach was to use Machine Learning algorithms to
        automatically analyse game replays and give detailed, personalised
        advice to players. Users of the system pay a monthly fee (around $10)
        for unlimited analysis of their games. Clarity Coach is currently in
        Early Access, but the service has already grown to hundreds of active
        users.
      </p>

      <Link to="/portfolio/clarity/silos/">Next</Link>
    </Scaffold>
  );
};
